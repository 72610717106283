// Step 1: Import React
import { Header } from '../components/header'
import { Footer } from '../components/footer'
import * as React from 'react'
import Panels from '../components/panels'
import HomepagePanel from '../components/homepagepanel'
import Seo from "../components/seo"
import { graphql } from "gatsby"
// Step 2: Define your component
const IndexPage = ({
  data: {
    allMdx: { edges },
  },
}) => {

  const servicesPanels = Panels(edges.filter(edge => edge.node.frontmatter.slug.startsWith("/services/")).slice(0, 3));
  const expPanel = Panels(edges.filter(edge => edge.node.frontmatter.slug.startsWith("/experience/")).slice(0, 3), true);
  const principlesPanel = Panels(edges.filter(edge => edge.node.frontmatter.slug.startsWith("/principles/")).slice(0, 3));

  return (
    <React.Fragment>
      <Header containerClasses="container" />
      <Seo title="Home" pathname="/" />
      <main class="mt-0">
      <div class="hp-hero pt-5 pb-3 bg-gradient pad-safe-lr">
      <div class="container">
      <div class="row mx-auto text-center">
        <h1 class="display-1 fw-bold" >Cloud-Native Architecture</h1>
        <div class="mx-auto col-md-8">
          <p class="lead my-4">There's more to using the cloud than deploying a few virtual machines. Use the right cloud technologies and get the same scale <i>&amp;</i> agility as the largest companies.</p>
          <p class="lead mb-4"><strong>Choose a partner who's done it before.</strong></p>
        </div>
        </div>
      </div>
      </div>
      <HomepagePanel linkDest="/services" sectionHeader="Our Services" background="hp-services bg-gradient">{servicesPanels}</HomepagePanel>
      <HomepagePanel linkDest="/experience" sectionHeader="Our Experience" background="hp-experience bg-gradient" hideSeeMore={true}>{expPanel}</HomepagePanel>
      <HomepagePanel linkDest="/principles" sectionHeader="Our Principles" background="hp-principles bg-gradient" hideSeeMore={true}>{principlesPanel}</HomepagePanel>
      </main>
      <Footer containerClasses="container" />
    </React.Fragment>
  )
}

export const pageQuery = graphql`
query frontpagequery {
  allMdx(
    filter: {frontmatter: { frontpage: {eq: true}}}
    sort: {fields: [frontmatter___priority, frontmatter___title], order: DESC}
  ) {
    edges {
      node {
        id
        frontmatter {
          slug
          title
          excerpt
          glyph
          tags
        }
      }
    }
  }
}
`
// Step 3: Export your component
export default IndexPage
