import * as React from 'react'
import ContentList from "./contentlist"
import { Link } from 'gatsby'

const HomepagePanel = (props) => {

    const seeMore = !props.hideSeeMore ? <div class="col text-end h3 pb-0 my-0"><Link className="hp-nav-text" to={props.linkDest}>See All <i className="bi bi-chevron-right"  ></i></Link></div>
        : ""

    return (
        <div className={props.background + " pad-safe-lr hp-panel"}>
            <div className="container pt-4">
                <div class="row">
                    <div class="col">
                        <h2 className="h3 pb-0 my-0"><Link className="hp-nav-text" to={props.linkDest}>{props.sectionHeader}</Link></h2>
                    </div>
                    {seeMore}
                </div>
                <div class="row gx-4">
                    <div class="py-2 col-12">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomepagePanel